'use strict'

###*
 # @ngdoc object
 # @name mundoComponents.controller:MundoConfirmBoxCtrl

 # @description

###
class MundoFormDialogCtrl
  ### @ngInject ###
  constructor: (
    title
    entityManager
    entity
    $mdDialog
    $log
    _
    $q
    $scope
  ) ->
    @entity = entity
    @title = title
    @errors = []
    @model = {}
    @progress = true
    @isEdit = false
    @hasSavedChanges = false

    init = =>
      @fields = entityManager.getForm(@entity)
      @progress = false
      @cancel = cancel
      @submit = submit
      @keys = (array) ->
        _.keys(array)

    ################
    cancel = =>
      $mdDialog.cancel @hasSavedChanges

    submit = (close = true) =>
      if @progress
        return

      @progress = true
      entityManager.submit(@model, entity).then(
        =>
          if close
            $mdDialog.hide('true')
          else
            @hasSavedChanges = true
            @fields = null
            @model = {}
            init()
        (resp) =>
          @progress = false
          @errors = extractErrors(resp.data.errors.children)
          for error in @errors
            $log.debug error
            @form[error.key].$setValidity('external', false)
      )

    approveForm = =>
      for error in @errors
        $log.debug error
        @form[error.key].$setValidity('external', true)

    extractErrors = (errorsArray) ->
      errors = []
      for key, value of errorsArray
        if value.errors?
          errors.push('key': key, 'value': value.errors)
      errors

    loadComplete = (data) =>
      $q.all  @entity = data

    $scope.$watch 'formDialogCtrl.model', (newValue) ->
      approveForm()
    , true

    if entity
      @isEdit = true
      entityManager.one(entity.id)
        .then (data) ->
          loadComplete(data)
            .then ->
              init()
    else
      init()

angular
  .module('mundoComponents')
  .controller 'MundoFormDialogCtrl', MundoFormDialogCtrl
